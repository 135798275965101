const zhCN = {
    home: "首页",
    product: "产品",
    aboutUs: "关于我们",
    buyNow: "立即购买",
    contactUs: "联系我们",

    ltd: "苏州索迩电子技术有限公司",

    amazon: "AMAZON",
    contact: "联系我们",
    privacy: "隐私政策",
    userAgreement: "使用条款",
    news: "新闻",
    search: "搜索",

    more: "更多"
};

export default zhCN;