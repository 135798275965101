<template>
  <footer id="Footer">
    <div class="container">
      <nav class="nav">
        <a href="https://www.amazon.com/dp/B09G6WWP1D" target="_blank">
          {{ $t("amazon") }}
        </a>
        <router-link to="/about-us?t=contact">{{ $t("contact") }}</router-link>
        <router-link to="/privacy">{{ $t("privacy") }}</router-link>
        <router-link to="/user-agreement">
          {{ $t("userAgreement") }}
        </router-link>
        <a @click="scroll">
          {{ $t("news") }}
        </a>
        <router-link to="/" class="search">{{ $t("search") }}</router-link>
      </nav>
      <div class="version">
        <img src="../../assets/images/footer-logo.png" class="footer-logo" />
        &copy;2023 {{ $t("ltd") }}
      </div>
      <p class="version">
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >苏ICP备2020063504号</a
        >
      </p>
    </div>
  </footer>
</template>
<script>
import { scroller } from "vue-scrollto/src/scrollTo";
const scrollTo = scroller();

export default {
  name: "Footer",

  data() {
    return {};
  },

  methods: {
    scroll() {
      if (this.$route.path != "/") {
        this.$router.push({
          path: "/",
          query: {
            t: "news",
          },
        });
      } else {
        setTimeout(() => {
          scrollTo("#news");
        }, 200);
      }
    },
  },
};
</script>
<style lang="less" scoped>
#Footer {
  height: 190px;
  color: #fff;
  background: #000;
}

.nav {
  text-align: center;
  line-height: 120px;

  a {
    padding-right: 55px;
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
    -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

    &:hover {
      opacity: 1;
    }

    &:last-child {
      padding: 0;
    }

    &.search {
      padding-right: 45px;
      position: relative;

      &:after {
        content: "";
        width: 27px;
        height: 27px;
        display: block;
        position: absolute;
        background: url(../../assets/images/search.png) no-repeat center;
        background-size: 28px;
        top: -1px;
        right: 4px;
      }
    }
  }
}

.version {
  position: relative;
  font-size: 14px;
  text-align: center;

  .footer-logo {
    width: 175px;
    top: 0;
    left: 0;
    position: absolute;
  }

  a {
    text-decoration: none;
    color: #fff;

    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: 1140px) {
  #Footer {
    padding: 10px 0;
    height: auto;
  }

  .nav {
    padding: 0 10px;
    line-height: 25px;

    a {
      padding: 0 10px;
      display: inline-block;
      font-size: 14px;

      &.search {
        padding-left: 10px;
        padding-right: 25px;

        &::after {
          width: 20px;
          height: 20px;
          background-size: 100%;
          top: 3px;
          right: 0;
        }
      }
    }
  }

  .version {
    font-size: 12px;
    text-align: center;

    .footer-logo {
      margin: 20px auto;
      width: 150px;
      position: static;
      display: block;
    }
  }
}
</style>
