import { createI18n } from "vue-i18n";
import messages from "./index";

const params = (() => {
    var params = {};
    var queryString = window.location.search.substring(1);

    var keyValuePairs = queryString.split('&');
    for (var i = 0; i < keyValuePairs.length; i++) {
        var keyValuePair = keyValuePairs[i].split('=');
        var key = decodeURIComponent(keyValuePair[0]);
        var value = decodeURIComponent(keyValuePair[1] || '');

        params[key] = value;
    }

    return params;
})();

const language = navigator.language.indexOf("zh") > -1 ? "zhCN" : "en";

const i18n = createI18n({
    locale: params.lang || localStorage.lang || language || "zhCN",
    messages
});

export default i18n;