const en = {
    home: "Home",
    product: "Product",
    aboutUs: "About Us",
    buyNow: "BUY NOW",
    contactUs: "Contact Us",

    ltd: "MOJAWA",

    amazon: "AMAZON",
    contact: "CONTACT",
    privacy: "PRIVACY",
    userAgreement: "USER AGREEMENT",
    news: "NEWS",
    search: "SEARCH",

    more: "MORE"
};

export default en;