<template>
  <header id="Header" :class="{ active: active }">
    <div class="container">
      <router-link to="/" @click="close">
        <img src="@/assets/images/logo.png" class="logo" />
      </router-link>

      <nav class="menu">
        <router-link to="/">{{ $t("home") }}</router-link>
        <router-link to="/product">{{ $t("product") }}</router-link>
        <router-link to="/about-us">{{ $t("aboutUs") }}</router-link>
      </nav>

      <a :href="product.link" target="_blank" class="buy">{{ $t("buyNow") }}</a>

      <a class="mobile btn-menu" v-show="!show" @click="open"> </a>

      <a class="mobile btn-menu-close" v-show="show" @click="close"> </a>

      <ul
        v-show="show"
        class="mobile menu-m animate__animated animate__faster"
        :class="animate"
      >
        <li>
          <router-link to="/" @click="close">
            {{ $t("home") }}
          </router-link>
        </li>
        <li>
          <router-link to="/product" @click="close">
            {{ $t("product") }}
          </router-link>
        </li>
        <li>
          <router-link to="/about-us" @click="close">
            {{ $t("aboutUs") }}
          </router-link>
        </li>
        <li>
          <a :href="product.link" target="_blank" @click="close">
            {{ $t("buyNow") }}
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",

  data() {
    return {
      show: false,
      animate: "",
      active: false,
      product: {},
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.getProduct();
    },

    async getProduct() {
      const { data } = await this.$fetch("/product/1");
      this.product = Object.assign(this.product, data.data);
    },

    open() {
      this.show = true;
      this.active = true;
      this.animate = "animate__zoomIn";
    },

    close() {
      const self = this;
      self.active = false;
      self.animate = "animate__zoomOut";
      setTimeout(() => {
        self.show = false;
      }, 400);
    },
  },
};
</script>
<style scoped lang="less">
#Header {
  margin: 20px 0 0 0;
  z-index: 100;
  position: relative;
}

.logo {
  height: 45px;
  vertical-align: middle;
}

.menu {
  display: inline-block;
  vertical-align: middle;

  a {
    margin: 0 0 0 35px;
    font-size: 18px;
    color: #000;
    text-decoration: none;
    opacity: 0.8;
    -webkit-transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

    &:hover {
      opacity: 1;
    }
  }
}

.btn-menu,
.btn-menu-close {
  width: 40px;
  height: 40px;
  line-height: 40px;
  float: right;
  cursor: pointer;

  &:after {
    width: 40px;
    height: 40px;
    display: block;
    content: "";
    background: url("../../assets/images/menu.png") no-repeat center;
    background-size: 30px 30px;
  }
}

.btn-menu-close {
  &:after {
    background-image: url("../../assets/images/close.png");
  }
}

.buy {
  width: 190px;
  height: 45px;
  line-height: 45px;
  float: right;
  background: #000;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 200;
  vertical-align: middle;
}

.mobile {
  display: none;
}

.menu-m {
  margin: 0;
  padding: 0;
  width: 100%;
  border-top: 1px solid #ddd;
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  background: #f9f9f9;
  z-index: 1010;
  list-style: none;

  li {
    padding: 0 40px;
    line-height: 45px;

    a {
      text-decoration: none;
      text-transform: uppercase;
      color: #000;
    }

    &:after {
      width: 100%;
      border-bottom: 1px solid #ddd;
      content: "";
      display: block;
    }

    &:last-child::after {
      display: none;
    }
  }
}

@media (max-width: 645px) {
  #Header {
    margin: 0;
    padding: 10px;
    z-index: 1010;

    &.active {
      width: 100%;
      height: 100%;
      position: fixed;
    }

    background: #f9f9f9;
  }

  .logo {
    height: 40px;
  }

  .menu {
    display: none;
  }

  .buy {
    display: none;
  }

  .mobile {
    display: block;
  }
}
</style>