import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/",
        component: () => import("./pages/Home")
    },
    {
        path: "/product",
        component: () => import("./pages/Product")
    },
    {
        path: "/privacy",
        component: () => import("./pages/Privacy")
    },
    {
        path: "/user-agreement",
        component: () => import("./pages/UserAgreement")
    },
    {
        path: "/about-us",
        component: () => import("./pages/AboutUs")
    },
    {
        path: "/download",
        component: () => import("./pages/Download")
    }
];

const VueRouter = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

export default VueRouter;